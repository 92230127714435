
.nav {
  position: fixed;
  z-index: 2;
}

a {
  color:#637B4B !important;
  text-decoration: none;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top:20px;
  padding-bottom:20px;
  padding-left:20px;
  padding-right:20px; 
  height: 100px;
  background-color: #F3EBDE;
}

.logo {
 width: 150px;
 margin-top:-22px;
}

.menu-toggle {
  cursor: pointer;
}

.nav-overlay {
  position: fixed;
  background: #F3EBDE;
  z-index: -2;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 1em;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

.hamBox {
  position: relative;
  width: 44px;
  height: 44px;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s ease;
  padding-top: 2.4em;
  padding-bottom: 2.4em;
  padding-left: 2.4em;
  padding-right: 0px;
}

.hamBox:hover {
  background: #F3EBDE;
}

.hamBoxOpen {
  background: #F3EBDE;
}

.lineTop,
.lineBottom {
  margin: 0 auto;
  position: absolute;
  display: block;
  width: 24px;
  height: 2px;
  border-radius: 10px;
  background: #637B4B;
  left: 0;
  right: 0;
  transform: rotate(0deg);
  transition: all 0.4s;
}

.lineTop {
  top: 2.2em;
}

.lineBottom {
  bottom: 2.2em;
}

.lineTop.spin {
  top: 2.35em;
  transform: rotate(135deg);
  background: #637B4B;
}

.lineBottom.spin {
  bottom: 2.35em;
  transform: rotate(225deg);
  background: #637B4B;
}

ul.nav-links {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 84vw;
}

ul.nav-links li {
  list-style: none;
  position: relative;
}

ul.nav-links li a {
  text-decoration: none;
  color: #637B4B;
  font-family: "Be Vietnam Pro";
  text-transform: uppercase;
  letter-spacing: -2px;
  font-size: 5em;
  position: relative;
  top: 0;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

ul.nav-links li a span{
  color: #637B4B;
  font-family: "New York";
}

.nav-item-wrapper:after {
  content: "";
  position: absolute;
  top: 120px;
  left: -10%;
  width: 120%;
  height: 100%;
  background: #F3EBDE;
  margin: 0 auto;
}

.nav-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 2em;
  display: flex;
  justify-content: space-between;
}

.nav-social-media ul {
  display: flex;
}

.nav-social-media ul li {
  list-style: none;
}

.nav-social-media ul li a {
  position: relative;
  bottom: -20px;
  text-decoration: none;
  font-family: sans-serif;
  color: #637B4B;
  text-transform: uppercase;
  padding-left: 2em;
  opacity: 0;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

.location {
  position: relative;
  bottom: -20px;
  opacity: 0;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
  font-family: sans-serif;
  text-transform: uppercase;
}

@media (max-width: 900px) {
  ul.nav-links li a {
    font-size: 3em;
  }

  .location {
    display: none;
  }

  .nav-footer {
    justify-content: space-around;
  }

  .nav-social-media ul li a {
    padding: 0 1em;
  }
}


