.img-container {
    display: flex;
    justify-content: center;
    height: 80vh;
}

h4 {
    font-size:1.7rem;
}

.column-home-img {
    max-width: 41vw;
    float:right;
    border-radius: 10px;
}

.header-style {
 justify-content: center;
 opacity: 90%;
}

.logo-header {
    width: 68vw;
    margin-top: -19vh;
}

.home-caption {
    font-size: 6vw;
    line-height: 1em;
    z-index: 1;
    position: absolute;
    text-align: center;
    font-family:'New York';
    top:35%;
    font-weight: 300;
}

.home-caption span {
    font-family:'Be Vietnam Pro';
}

.some-page-wrapper {
    margin-top: 80px;
}

.footer{
    padding-top:100px;
    display: flex;
    padding-bottom:20px;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

}
  
.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
  
.column {
    display: flex;
    margin-right: 30px;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}
  
.double-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2;
}
  
.column-g1 {
    padding-top:10px;
    margin-right:30x;
    margin-bottom:30x;
}
  
.column-g2 {
    margin-left:30x;
    margin-bottom:30x;
}


.intro h2 {
   font-size: 20px;
   font-weight: 300px;
   margin-bottom:30px;
}

.home-button {
    font-size: 30px;
    font-weight: 300px;
    padding: 20px 20vw;
    margin-top:5vh;
    background-color: #F3EBDE;
    border: 2px solid #637B4B;
    border-radius: 10% / 80%;
    color: #637B4B;
    text-transform: uppercase;
}

.home-button:hover{
    font-size: 30px;
    font-weight: 300px;
    padding: 20px 20vw;
    margin-top:30px;
    background-color: #637B4B;
    border: 2px solid #F3EBDE;
    border-radius: 10% / 80%;
    color: #F3EBDE !important;
    transition: 0.3s;
}

.m-about-slidingtext {
    position: absolute;
    height: 3em;
    overflow: hidden;
    left: 0;
    font-size: 4.5vw;
    line-height: 2em;
    text-transform: uppercase;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    width: 100vw;
}

.m-about .m-about-slidingtext div, .m-works-container .m-about-slidingtext div {
    position: absolute;
    top: 0;
    left: 0;
    line-height: 100%;
    white-space: nowrap;
}

.mHorizSlide {
    animation-name: mHorizSlide;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@media (max-width: 780px) {
    .mHorizSlide {
      animation-duration: 12s;
    }
}
  
@keyframes mHorizSlide {
    from {transform: translateX(100%);}
    to {transform: translateX(-100%);}
}

.mHorizSlide {
    /* transform: translate(-0.5%, 0%); */
    animation-name: mHorizSlide;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@media (max-width: 780px) {
    .mHorizSlide {
      animation-duration: 12s;
    }
}
  
@keyframes mHorizSlide {
    from {transform: translateX(100%);}
    to {transform: translateX(-100%);}
}


@media only screen and (max-width: 600px) {

    .img-container {
        height: 70vh;
    }

    .home-caption {
        font-size: 2em;
        top:38%;
    }

    .logo-header {
        width: 70vw;
        margin-top: -3vh;
    }

    .header-style {
      max-width: 90vw;
      align-self: center;

    }

    .home-button {
        padding: 20px 33vw;
        border-radius: 10% / 50%;
       
    }

    .column-home-img {
        margin-top:50px;
        max-width: 100%;
        border-radius: 10px;
    }
    

    .column {
        display: flex;
        margin-right: 0px;
        flex-direction: row;
        flex-basis: 100%;
        margin-top:0px;
    }

    .column-g1 {
        padding-top:0px;
        padding-right:5px;
    }

    .m-about-slidingtext {
       padding-top:20px;
    }

    .some-page-wrapper {
        margin-top: 0px;
    }

    h4 {
        font-size:1.25rem;
    }
}


.wrapper-mark {
    max-width: 100%;
    overflow: hidden;
    margin-top:100px;
}
  
.scritta {
    white-space: nowrap;
    font-size:4vw;
    overflow: hidden;
    display: inline-block;
    animation: marquee 10s linear infinite;
    text-transform: uppercase;
}

.serifo {
    font-family:'Paris';
}




  
.scritta p {
    display: inline-block;
}
  
@keyframes marquee {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(-50%, 0, 0);
    }
}

@media only screen and (max-width: 600px) {
    .scritta p {
        font-size: 12vw;
    }

    .wrapper-mark {
        margin-top:150px;
    }
}
  