@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../public/fonts/BeVietnamPro-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Paris";
  src: url("../public/fonts/paris.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "New York";
  src: url("../public/fonts/NewYork.otf");
  font-weight: normal;
  font-style: normal;
}

.footer{
  padding-top:100px;
  display: flex;
}


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Be Vietnam Pro', sans-serif;;
}

@media only screen and (max-width: 600px) {
  .footer{
      padding-top:200px;
      display: flex;
      padding-bottom:20px;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      font-size:10px;
  }
}
