h2 {
    text-transform: uppercase;
    font-size: 3em;
    color: #637B4B;
    font-family: 'New York';
} 

h4 {
    margin-bottom: 0px;
    font-weight: 300;
} 

li {
    border-bottom: 0px solid #637B4B;
} 

h3 {
    text-transform: uppercase;
    font-size: 2em;
    color: #637B4B;
    font-weight: 400;
} 

.title__container {
    border-bottom: 2px solid #637B4B;
    margin-top:10px;
    display: flex;
    flex-shrink: row;
    justify-content: space-between;
} 


.ty-collapse-item__title {
   text-transform: uppercase;
   font-size: 2em;
   color: #637B4B;
   order: -1;
}

.ty-collapse .ty-collapse_borderless {
    background-color: #000;
}

.ty-collapse-item {
    background-color: #F3EBDE;
}

.ty-collapse-item__header:hover {
    background-color: #F3EBDE;
    border-bottom: 2px solid #637B4B;
}

.ty-collapse-item__header {
    padding-top:12px;
    padding-bottom:12px;
    padding-left:0px;
    padding-right:0px;
    border-bottom: 0px solid #637B4B;
}

.ty-collapse {
    border-bottom: 0px solid #637B4B;
}

.ty-collapse-transition {
    border-bottom: 0px solid #637B4B;
}

::marker {
    color: #F3EBDE;
}

.description__item {
    color: #637B4B;
    margin-bottom:12px;
    margin-top:12px;
}

.ty-collapse-item__content {
   padding-left: 0px;
   padding-right:0px;
   border-bottom:0px;
}

.ty-collapse-item__header {
  padding-right:0px;
}

.ty-collapse-item__content {
    background-color: #F3EBDE;
}

.ty-collapse-item__arrow {
    color: #637B4B;
}

.ty-collapse-item__content {
    border-top: 0px;
}

.price__container {
    padding-right:20px;
}

.ty-collapse .ty-collapse_borderless {
   border-bottom:0px;
}

li {
 margin-bottom: 30px;
}
 
.ty-collapse_borderless  .ty-collapse-item:last-child  {
    border-bottom:0px;
}

.section__container  {
    border-bottom:2px solid #637B4B;
    padding-bottom:5px;
    padding-top:10px;
}

.description__container {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom:2px solid #637B4B;
}

.item__image {
    display:flex;
    max-width: 130px;
    border-left:2px solid #637B4B;
    border-right:2px solid #637B4B;
}

.labels__item {
    justify-content: flex-start;
}

.labels__container {
    display:flex;
    justify-content: flex-start;
}

.lista-allergeni {
    margin-bottom: 2px ;
}
    

.description__container {
    display: flex;
}

.space-allergeni{
    margin-top: 10px;
}

@media (max-width: 900px) {
    .ty-collapse-item__title {
        font-size: 1.4em;
    } 

    .ty-collapse-item__header {
        padding-bottom:4px;
    } 

    h3 {
        font-size: 1.4em;
    } 

}