* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  background: #F3EBDE;
  color: #637B4B;
}
