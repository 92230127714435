.row {
    margin-top:30vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
  
.column {
    display: flex;
    margin-right: 30px;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
}

.titolo {
    font-size: 59px;
}

.column-img {
    max-width: 36vw;
    float:right;
    border-radius: 10px;
}

.telefono {
   margin-top:10px;
}

.social {
    margin-top:10px;
}
 

.orari {
    margin-top:20px;
    font-size: 1.7em;
}


.footer{
    padding-top:200px;
    display: flex;
    padding-bottom:20px;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
}
  
.double-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 2;
}
  
.column-g1 {
    padding-top:10px;
    margin-right:30x;
    margin-bottom:30x;
}
  
.column-g2 {
    margin-left:30x;
    margin-bottom:30x;
}


@media only screen and (max-width: 600px) {


    .column {
        display: flex;
        margin-right: 0px;
        flex-direction: row;
        flex-basis: 100%;
        margin-top:0px;
    }

    .column-g1 {
        padding-top:0px;
        padding-right:5px;
    }

    .column-img {
        max-width: 89vw;
        float:right;
        border-radius: 10px;
        margin-top:40px;
    }

    .row {
        margin-top:0vh;
    }

    .footer{
        padding-top:180px;
        display: flex;
        padding-bottom:20px;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
    }

    .orari {
        font-size: 1.25rem;
    }
    
}